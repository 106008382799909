import React from 'react';
import BootstrapNavbar from '../components/navgationBar/navigationBar';
import * as Styled from '../styles/index';
import GlobalStyles from '../styles/globalStyles';
import HeaderWithParagraphList from '../components/headerWithParagraphList/headerWithParagraphList';
import HeaderWithParagraph from '../components/headerWithParagraph/headerWithParagraph';
import {
  FAQ1Data,
  FAQ2Data,
  FAQ3Data,
  FAQ4Data,
  FAQ5Data,
  SafetySuggestionData,
  SafetyKeepData,
} from '../utils/constant';
import { Helmet } from 'react-helmet';
import isExternalUrl, { addTrailingSlash, isExternalUrlhref } from '../utils';
import Footer from '../components/footer/footer';
import BackButton from '../components/helpBackButton/helpBackButton';
export default function FAQ() {
  const Link =
    'https://propane.ca/wp-content/uploads/2016/08/CPA_Fact-Sheet_Cylinder-Markings_August-2016.pdf';
  return (
    <>
      <Helmet>
        <title>FAQ | Pro2Go</title>
      </Helmet>
      <GlobalStyles />
      <BootstrapNavbar />
      <Styled.MainContainer>
        <h1>Frequently Asked Questions</h1>
        <HeaderWithParagraph
          header={FAQ1Data.header}
          paragraph={FAQ1Data.paratext}
        />
        <h5>Can I store my cylinder in my garage?</h5>
        <p>
          <strong>
            PROPANE CYLINDERS ARE NOT PERMITTED TO BE STORED IN ANY BUILDING.
          </strong>
        </p>
        <HeaderWithParagraph
          header={FAQ2Data.header}
          paragraph={FAQ2Data.paratext}
        />
        <h5>Where can I find the date on my cylinder?</h5>
        <p>
          Look for a date stamp on or near the collar of the cylinder. The date
          (month & year of re-qualification inspection) is usually stamped on
          the edge of the cylinder collar. For more information{' '}
          <a
            href={isExternalUrlhref(Link) ? Link : addTrailingSlash(Link)}
            target={isExternalUrl(Link) ? '_blank' : '_self'}
          >
            click here.
          </a>
        </p>
        <HeaderWithParagraph
          header={FAQ3Data.header}
          paragraph={FAQ3Data.paratext}
        />
        <HeaderWithParagraph
          header={FAQ4Data.header}
          paragraph={FAQ4Data.paratext}
        />
        <HeaderWithParagraph
          header={FAQ5Data.header}
          paragraph={FAQ5Data.paratext}
        />
        <h5>
          How do I find the retesting/requalification dates on my BBQ cylinder?
        </h5>
        <p>
          For detailed information{' '}
          <a
            href={isExternalUrlhref(Link) ? Link : addTrailingSlash(Link)}
            target={isExternalUrl(Link) ? '_blank' : '_self'}
          >
            click here.
          </a>
        </p>
        <HeaderWithParagraphList
          subHeader={SafetySuggestionData.header}
          paragraph={SafetySuggestionData.paragraph}
          unOrderedList={SafetySuggestionData.unOrderedList}
        />
        <HeaderWithParagraphList
          subHeader={SafetyKeepData.header}
          paragraph={SafetyKeepData.paragraph}
          unOrderedList={SafetyKeepData.unOrderedList}
        />
        <BackButton />
      </Styled.MainContainer>
      <Footer />
    </>
  );
}
